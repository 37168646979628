import { useFormik } from "formik";
import Link from "next/link";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useRouter } from "next/router";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import {
  authenticate,
  getSession,
} from "../../components/auth/login/loginUser";
import { Card } from 'antd';
import {
  approvePatient,
} from "../../lib/helper/patient";
import { Amplify, Auth, Hub } from "aws-amplify";
import { toast, ToastContainer } from "react-toastify";

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "us-east-2",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "us-east-2_vpCRfIvXD",
    //userPoolId: "us-east-2_ygEEuJ9rg",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "66fovpj4vcp9488lb9o7n3rv58",
    //userPoolWebClientId: "42coq7iutjmqjf5n4jodrn5agr",
    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: "nextmed-patients.auth.us-east-2.amazoncognito.com",
      //domain: "nextmed-patients-new.auth.us-east-2.amazoncognito.com",
      //scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      scope: ["email", "profile", "openid"],
      redirectSignIn: "https://www.joinnextmed.com/login",
      //redirectSignOut: 'http://localhost:3001/',
      responseType: "token", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});
const currentConfig = Auth.configure();
const initialValues = {
  email: "",
  password: "",
};
const getCurrentUser = async (data) => {
  if (data.payload.data.signInUserSession) {
    let signInUserSession = data.payload.data.signInUserSession;
    window.sessionStorage.setItem("token", signInUserSession.idToken.jwtToken);
    window.sessionStorage.setItem(
      "name",
      signInUserSession.idToken.payload.name
    );
    window.sessionStorage.setItem(
      "email",
      signInUserSession.idToken.payload.email
    );
    window.sessionStorage.setItem("isLoggedIn", true);
  }
};

// validate value of email and password
const validate = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = "Email is required";
  } else if (
    !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
  ) {
    errors.email = "Please provide a valid email";
  }
  if (!values.password) {
    errors.password = "Password is required";
  }
  return errors;
};
var cognitoUser = CognitoUser;
const Login = (props) => {
  const router = useRouter();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [otp, setOtp] = useState(false);
  const [resendBtn, setResendBtn] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [text_message, setText_message] = useState({
    head: null,
    para: null
  })
  const [redirect, setRedirect] = useState(
    window.location.href.split("?")[1]
      ? window.location.href.split("?")[1].split("=")[1]
      : "visits"
  );
  const [username, setUsername] = useState("");
  const [answer, setAnswer] = useState("");

  Hub.listen("auth", (data) => {
    if (data?.payload?.data?.code == "NotAuthorizedException") {
      setError(
        `Your one time code has expired, please resend the code and try again.`
      );
      setResendBtn(true);
      setAnswer("");
      setOtp(false);
    } else {
      switch (data.payload.event) {
        case "signIn":
          setSuccess("Welcome Back!  ");
        case "cognitoHostedUI":
          getCurrentUser(data);
          break;
        case "signIn_failure":
          setError(
            `<p className="error-p text-danger">No account found for ${data.payload.message.split(" ")[0]}. Please call us at <a className="text-danger" href="tel:+18555501960">(855) 550-1960</a> for immediate assistance in accessing your account or email us at <a className="text-danger" href="mailto:team@joinnextmed.com">team@joinnextmed.com</a>. We may need to reset your account or update your email address</p>.
            `
          );
        case "cognitoHostedUI_failure":
          setError(
            `<p className="error-p text-danger">No account found for ${data.payload.message.split(" ")[0]}. Please call us at <a className="text-danger" href="tel:+18555501960">(855) 550-1960</a> for immediate assistance in accessing your account or email us at <a className="text-danger" href="mailto:team@joinnextmed.com">team@joinnextmed.com</a>. We may need to reset your account or update your email address</p>.
           `
          );

          break;
        default:
          break;
      }
    }
  });

  async function signIn(email1) {
    cognitoUser = await Auth.signIn(email1);
    if (cognitoUser) {
      setOtp(true);
      setResendBtn(false);
      setOtpLoading(false);
    } else {
      toast.warn("Please check your email!");
      setOtpLoading(false);
    }

  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setOtpLoading(true);
    //alert(username);
    if (!/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(username.trim())) {
      setError("There is no account associated with this email. Please try another or contact us at team@joinnextmed.com");
      setOtpLoading(false);
    } else {
      signIn(username.trim());
      setError("");
    }
    setTimeout(() => {
      setOtpLoading(false);
    }, 3000);
  };



  useEffect(() => {
    if (props && props.success && props.success === "true") {
      setText_message((prev) => {
        return {
          ...prev,
          head: "Thanks for your order!",
          para: "Login and fill your intake form to move on to your doctor visit and lab test."
        }
      })
    }
  }, [])
  async function answerCustomChallenge(answer) {
    // Send the answer to the User Pool
    // This will throw an error if it’s the 3rd wrong answer
    cognitoUser = await Auth.sendCustomChallengeAnswer(cognitoUser, answer);
    ;
    // It we get here, the answer was sent successfully,
    // but it might have been wrong (1st or 2nd time)
    // So we should test if the user is authenticated now
    try {
      // This will throw an error if the user is not yet authenticated:
      const currSessionData = await Auth.currentSession();

      if (currSessionData) {
        window.sessionStorage.setItem(
          "token",
          currSessionData.idToken.jwtToken
        );
        window.sessionStorage.setItem(
          "refershToken",
          currSessionData.refreshToken.token
        );
        window.sessionStorage.setItem(
          "acessToken",
          currSessionData.accessToken.jwtToken
        );

        window.sessionStorage.setItem(
          "name",
          currSessionData.idToken.payload.name
        );
        window.sessionStorage.setItem(
          "email",
          currSessionData.idToken.payload.email
        );
        window.sessionStorage.setItem("isLoggedIn", true);
      }

      if (router.query.followup) {
        router.push("/followup");
      } else {
        if (props && props.existing_user == "true") {
          router.push("/checkout");
        } else {
          if (props.load_page == "cc") {
            router.push("/information");
          } else {
            if (props.load_page == "wl") {
              let payload = {
                email: currSessionData.idToken.payload.email,
                approve: "0",
              };
              const approve = await approvePatient(payload);
              // router.push("/wl_checkout");
            } else {
              if (props && props.existing_user == "true") {
                router.push("/checkout");
              } else {
                if (props && props.page != undefined) {
                  if (props && props.id == undefined) {
                    router.push("/" + props.page);
                  } else {
                    router.push("/" + props.page + "?id="
                      + props.id);
                  }
                } else {
                  router.push("/visits");
                }
              }
            }

          }
        }
      }

    } catch {
      setError("You didn't type the right code!");
      setLoginLoading(false)
    }
  }
  const handleOtp = (event) => {
    event.preventDefault()
    setLoginLoading(true);
    if (/\b\d{6}\b/g.test(answer)) {
      answerCustomChallenge(answer);
    } else {
      setError("Please enter the valid otp!");
      setLoginLoading(false)
    }
  };
  const resetOtp = (event) => {
    event.preventDefault()
    setLoginLoading(false);
  }
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      let data = await authenticate(values.email, values.password)
        .then((data) => {
          if (data.status === "setpassword") {
            setError("Your account has not been confirmed.");

          } else {
            getSession().then(async (results) => {
              window.sessionStorage.setItem("token", results.idToken.jwtToken);
              window.sessionStorage.setItem("name", results.name);
              window.sessionStorage.setItem("email", formik.values.email);
              window.sessionStorage.setItem("isLoggedIn", true);
              if (props && props.existing_user == "true") {
                router.push("/checkout");
              } else {
                if (props && props.page == "refund") {
                  router.push("/refund");
                } else {
                  router.push("/visits");
                }
              }
            });
          }
        })
        .catch((e) => {
          setError(e.message);
        });
    },
    validate,
  });
  useEffect(() => {
    if (window.sessionStorage.getItem("isLoggedIn") === "true") {
      router.push("/" + redirect);
    }
  }, []);
  useEffect(() => {
    if (props.load_page != undefined) {
      if (props.load_page == "cc" || props.load_page == "wl") {
        Cookies.set("load_page", props.load_page);
      } else {
        Cookies.remove("load_page");
      }
    } else {
      Cookies.remove("load_page");
    }
  }, []);
  return (
    <div className="login-section">
      <div className="login-page-logo">
        <Link href="/">
          <img src="/images/logo.webp" />
        </Link>
      </div>
      <div className="section-gap">
        <div className="container">

          {text_message.head && (<h3 className="text-center"><p>{text_message.head}</p>
            <b>IMPORTANT: </b>  {text_message.para}
          </h3>)}

          <Card bordered={true} className="login-size">

            <h3 className="text-center">Welcome Back</h3>
            <small className="text-center">
              Log in to your Next Medical platform account to continue
            </small>

            <form onSubmit={(e) => { handleSubmit(e) }}>
              <input
                className="lg-form-control w-100 mt-2"
                type="text"
                placeholder="Enter your email address"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                disabled={otp == true}
              />
              {otp == false && resendBtn == false && (

                <>
                  {otpLoading == true ?
                    <button
                      type="submit"
                      className="btn btn-lg-primary w-100 mt-2"
                      disabled
                    >
                      Loading...
                    </button> :
                    <button
                      type="submit"
                      className="btn btn-lg-primary w-100 mt-2"
                    >
                      Get one time password
                    </button>}
                </>
              )}
            </form>



            {otp == true && resendBtn == false && (
              <form onSubmit={(e) => { handleOtp(e) }}>
                <div className="text-center mt-2"></div>{" "}
                <input
                  type="text"
                  className="lg-form-control w-100 mt-2"
                  placeholder="One time code (check your phone or email)"
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                />
                {loginLoading == true ?
                  <button
                    className="btn btn-lg-primary w-100 mt-2"
                    type="submit"
                    disabled
                  >loading...
                  </button> :
                  <button
                    className="btn btn-lg-primary w-100 mt-2"
                    type="submit"
                  >Login
                  </button>}
              </form>
            )}


            {resendBtn == true && (
              <>
                {otpLoading == true ?
                  <button
                    type="submit"
                    className="btn btn-lg-primary w-100 mt-2"
                    disabled
                  >
                    Loading...
                  </button> :
                  <button
                    onClick={(e) => {
                      handleSubmit(e)
                      resetOtp(e)
                    }}
                    className="btn btn-lg-primary w-100 mt-2"
                  >
                    Resend Otp
                  </button>}
              </>
            )}
            {error !== "" && (
              <p
                className="text-center mt-2"
                style={{ color: "red", fontSize: "12px" }}
              >
                <div className="main-p-div"
                  dangerouslySetInnerHTML={{ __html: error }}
                />
              </p>
            )}
            {success !== "" && (
              <p
                className="text-center mt-2"
                style={{ color: "green", fontSize: "12px" }}
              >
                {success}
              </p>
            )}

            <p className="small-txt text-center mt-2">
              Note: If you used Apple Pay to purchase, please ensure you are
              using your email associated with your Apple Pay account.
            </p>
          </Card>
          <ToastContainer theme={"colored"} />
        </div>
      </div>
    </div>
  );
};
export default Login;

export async function getServerSideProps(context) {
  const data = context.query;
  return {
    props: {
      existing_user: data.existing_user ? data.existing_user : null,
      load_page: data.load_page ? data.load_page : null,
      page: data.page ? data.page : null,
      success: data.success ? data.success : null,
      followup: data.followup ? data.followup : null,
      id: data.id ? data.id : null
    },
  };
}
